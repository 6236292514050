<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-layout row justify-space-between align-center>
            <v-flex xs12 sm6 md6 lg6>
              <span class="heading">Stock Details</span>
            </v-flex>
            <v-flex  xs12 sm6 md6 lg6 pt-2 pt-lg-0 pt-md-0 pt-sm-0 class="text-right">
              <v-btn color="#fdac53" @click="adddialog = true">
                <span
                  style="
                    font-size: 12px;
                    font-family: poppinssemibold;
                    color: black;
                  "
                >
                  Add Stock
                  <v-icon color="black">mdi-plus</v-icon>
                </span>
              </v-btn>
            </v-flex>

            <v-dialog v-model="adddialog" max-width="700px">
              <v-card rounded="lg">
                <v-card-title>
                  <span class="heading">ADD STOCK</span>
                  <v-spacer></v-spacer>
                  <v-btn color="red" icon @click="adddialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <v-form>
                    <v-layout wrap justify-start pt-2>
                      <v-flex xs12>
                        <v-layout
                          class="pb-2"
                          wrap
                          align-center
                          v-for="(entry, index) in stockData"
                          :key="entry._id"
                        >
                          <v-flex xs12 sm12 md12 lg6 pr-2>
                            <!-- Filter the venomArray based on selected venoms -->
                            <v-select
                              outlined
                              dense
                              :items="getFilteredVenoms(index)"
                              v-model="entry.venom"
                              placeholder="Select Venom"
                              class="text-field"
                              item-text="name"
                              item-value="_id"
                            ></v-select>
                          </v-flex>

                          <v-flex xs12 sm12 md12 lg5>
                            <v-text-field
                              class="text-field"
                              placeholder="Stock"
                              outlined
                              dense
                              type="number"
                              v-model="entry.stock"
                            ></v-text-field>
                          </v-flex>
                          <v-flex pl-lg-6 pl-sm-3 pl-md-3 pl-0>
                            <v-btn
                              icon
                              @click="removeEntry(index)"
                              color="red"
                              small
                              v-if="stockData.length > 1"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex pt-2>
                        <v-btn
                          outlined
                          :color="appColor"
                          @click="addEntry"
                          small
                          :disabled="isAddDisabled"
                        >
                          <v-icon>mdi-plus</v-icon>More
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>

                <v-card-actions class="py-4 justify-end headline lighten-2">
                  <v-btn
                    tile
                    outlined
                    color="#FF1313"
                    light
                    :ripple="false"
                    depressed
                    class="itemValue"
                    text
                    @click="adddialog = false"
                    ><span style="color: black">Cancel</span>
                  </v-btn>
                  <v-btn
                    tile
                    :color="appColor"
                    light
                    :ripple="false"
                    depressed
                    class="itemValue"
                    @click="itemadd()"
                    ><span style="color: white">Save Changes</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
          <v-flex xs12 v-if="items && items.length > 0">
            <v-layout wrap justify-start pt-2>
              <!-- <v-flex xs12 sm6 md6 lg6 class="subheading">
                  <span>Rows per page</span>
                  <v-select
                    v-model="count"
                    color="#FF6907"
                    outlined
                    dense
                    hide-details
                    :items="[20, 30, 50]"
                  ></v-select>
                </v-flex> -->
              <!-- <v-flex xs12 sm6 md6 lg6 pl-md-2 pl-lg-2 pl-sm-2>
                  <span class="subheading">Filter</span>
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    placeholder="Type To Search"
                    v-model="keyword"
                  >
                  </v-text-field>
                </v-flex> -->
            </v-layout>
            <v-layout wrap justify-start pt-4>
              <v-flex xs12>
                <v-card>
                  <div style="overflow-y: auto; overflow-x: auto;">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left tablehead">Venom</th>
                        <th class="text-left tablehead">Stock</th>
                        <th class="text-left tablehead action-column">
                          <span class="pl-3">Edit</span>
                        </th>
                        <!-- Apply action-column class -->
                        <th class="text-left tablehead action-column">
                          Delete
                        </th>
                        <!-- Apply action-column class -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <td  style="white-space: nowrap;" class="tableitems">{{ item.venom.name }}</td>
                        <td  style="white-space: nowrap;" class="tableitems">{{ item.stock }}</td>
                        <td  style="white-space: nowrap;" class=" action-column">
                         
                          <v-icon
                          small
                          color="primary"
                          class="ml-4"
                          @click.stop="editSlider(item)"
                        >
                          mdi-pencil
                        </v-icon>
                        </td>
                        <td  style="white-space: nowrap;" class=" action-column">
                          <v-icon
                          small
                          color="error"
                          class="ml-4"
                          @click.stop="opendeleteDialog(item)"
                        >
                          mdi-delete
                        </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-4 v-if="pages > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination :length="pages" v-model="currentPage"  color="#FF6907"
            circle></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout wrap justify-center align-center>
          <v-flex xs12 class="text-center">
            <span class="nodata">No Data Found !</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog v-model="editdialog" max-width="700px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="heading">EDIT VENOM STOCK</span>
          <v-spacer></v-spacer>
          <v-btn color="red" icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-layout wrap justify-start pt-2>
              <v-flex
                xs12
                sm12
                md12
                lg12
                class="subheading"
                v-if="editingitem.venom"
              >
                <span style="color: black">Name</span>
                <v-text-field
                  class="pt-2 text-des"
                  outlined
                  disabled
                  v-model="editingitem.venom.name"
                  dense
                  hide-details
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-3 class="subheading">
                <span style="color: black">Stock</span>
                <v-text-field
                  outlined
                  v-model="editingitem.stock"
                  dense
                  hide-details
                  class="pt-2 text-des"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-card-actions class="py-4 justify-end headline lighten-2">
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            class="itemValue"
            @click="closeDialog()"
            ><span style="color: black">Cancel</span>
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            class="itemValue"
            @click="edit(editingitem._id)"
            ><span style="color: white">Save Changes</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text>Are you sure you want to delete this venom?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="deleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="rejectDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Confirmation</v-card-title>
          <v-card-text
            >Are you sure you want to reject?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="rejectDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="rejectoperation()"
              class="itemValue"
            >
              <span style="color: #fff">Submit</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
        currentPage: 1,
        pages: 0,
        limit: 10,
      name: "",
      desc: "",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      // count: 20,
      keyword: "",
      // currentPage: 1,
      // Pagelength: 0,
      userlist: [],
      items: [],
      status: "",
      deleteDialog: false,
      deleteid: "",
      rejectDialog: false,
      rejectid: "",
      addEventListenerdialog: false,
      adddialog: false,
      editdialog: false,
      editingitem: [],

      venomArray: [],
      stockData: [
        {
          venom: "",
          stock: "",
        },
      ],
    };
  },
  mounted() {
    this.getData();
    this.getVenom();
  },
  //   mounted(){
  //         this.getData()
  //     },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
    },
  },
  computed: {
    // Check if all venoms have been selected
    isAddDisabled() {
      // Check if all items from venomArray are already selected
      const selectedVenoms = this.stockData
        .map((entry) => entry.venom)
        .filter((venom) => venom !== null);

      return selectedVenoms.length >= this.venomArray.length;
    },
  },

  methods: {
    getFilteredVenoms(index) {
      const selectedVenoms = this.stockData
        .map((entry) => entry.venom)
        .filter((venom) => venom !== null);

      return this.venomArray.filter(
        (venom) =>
          !selectedVenoms.includes(venom._id) ||
          venom._id === this.stockData[index].venom
      );
    },
    addEntry() {
      this.stockData.push({
        venom: "",
        stock: "",
      });
    },
    removeEntry(index) {
      this.stockData.splice(index, 1);
    },
    closeDialog() {
      this.editdialog = false;
      this.getData();
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/get/venom/stock/list/by/hospital",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          //keyword: this.keyword,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.items = response.data.data;
              this.pages = Math.ceil(response.data.totalLength / this.limit);
              //   this.venomArray=this.items.filter(x=>x.venom._id)
              var venomIds = this.items.map((x) => x.venom._id);
              this.venomArray = this.venomArray.filter(
                (x) => !venomIds.includes(x._id)
              );
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },

    getVenom() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/list/venoms",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.venomArray = response.data.data;
              //this.Pagelength = response.data.pages;
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },

    itemadd() {
      // Validation for venom and stock inside stockData
      for (let i = 0; i < this.stockData.length; i++) {
        const entry = this.stockData[i];

        // Check if venom is not selected
        if (!entry.venom || entry.venom.length === 0) {
          this.msg = `Please select venom for entry ${i + 1}`;
          this.showSnackBar = true;
          return;
        }

        // Check if stock is not provided or invalid
        if (!entry.stock || isNaN(entry.stock) || entry.stock <= 0) {
          this.msg = `Please provide a valid stock for entry ${i + 1}`;
          this.showSnackBar = true;
          return;
        }
      }

      // Proceed if validation passes
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/update/venom/stock",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          stockData: this.stockData.map((entry) => ({
            venom: entry.venom,
            stock: entry.stock,
          })),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.adddialog = false;
            this.getData(); // Refresh the data
            this.stockData = [
              {
                venom: "",
                stock: "",
              },
            ]; // Reset stockData after submission
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    //   itemadd() {
    //   const data = {

    //     stockData: this.stockData.map((entry) => ({
    //       venom: entry.venom,
    //       stock: entry.stock,

    //     })),
    //   };

    //   axios({
    //     url: "/update/venom/stock",
    //     method: "POST",
    //     data: data,
    //     headers: {
    //         "x-auth-token": localStorage.getItem("token"),
    //       },
    //   })
    //     .then((response) => {

    //       if (response.data.status) {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //         this.adddialog = false;
    //         this.getData();
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {

    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      this.appLoading = true;

      // Construct the stockData array with venom name and stock
      let stockData = [
        {
          venom: this.editingitem.venom._id, // Selected venom name
          stock: this.editingitem.stock, // Stock value
        },
      ];

      axios({
        url: "/update/venom/stock",
        method: "POST",
        data: {
          stockData: stockData, // Pass the stockData array
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData(); // Refresh data after successful edit
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    opendeleteDialog(item) {
      this.deleteid = item._id;
      this.deleteDialog = true;
    },
    deleteoperation() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/delete/stock/entry",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.deleteid,
        },
      })
        .then((response) => {
          this.deleteDialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getVenom();
            this.getData();
           
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //   rejectoperation() {
    //     this.appLoading = true;
    //     axios({
    //       method: "POST",
    //       url: "/operation/removedeleterequest",
    //       headers: {
    //         "x-auth-token": localStorage.getItem("token"),
    //       },
    //       data: {
    //         id: this.rejectid,
    //       },
    //     })
    //       .then((response) => {
    //         if (response.data.status == true) {
    //           {
    //             this.msg = "Rejected Successfully.";
    //             this.showSnackBar = true;
    //             this.appLoading = false;
    //             this.rejectDialog=false;
    //           }
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showSnackBar = true;
    //           this.appLoading = false;
    //           this.rejectDialog=false;

    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
  },
};
</script>
<style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}

.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
</style>